
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'BaseDropdown',
})
export default class BaseDropdown extends Vue {
  @Prop({ default: false })
  isOpen!: boolean

  @Prop({ default: [] })
  dropdownList!: any[]

  @Prop({ default: '' })
  listItemPropertyName!: string

  public getIitemByProperty(item: any): any {
    if (this.listItemPropertyName) {
      return item[this.listItemPropertyName]
    }
    return item
  }

  public toggleDropdown(): void {
    return
  }
}
