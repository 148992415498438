
import { Component, Prop } from 'vue-property-decorator'
import { required, maxLength } from 'vuelidate/lib/validators'
import { ImplementerDescriptionModel } from '@/store/modules/implementer.module'

import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import BaseDropdown from '@/components/forms/BaseDropdown.vue'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'
import vClickOutside from 'v-click-outside'

const validations = {
  fields: {
    companyName: { required, maxLength: maxLength(250) },
    fullName: { required, maxLength: maxLength(250) },
    city: { required, maxLength: maxLength(250) },
    phone: { required, maxLength: maxLength(250) },
    profileDescription: { required, maxLength: maxLength(3000) },
    isShowOnSite: { required },
  },
}

@Component({
  name: 'ImplementerProfileDescriptionForm',
  components: {
    FormGroup,
    Field,
    BaseTextarea,
    BaseCheckbox,
    BaseDropdown,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  validations,
})
export default class ImplementerProfileDescriptionForm extends BreakpointsMixin {
  @Prop({ required: true })
  value!: ImplementerDescriptionModel

  @Prop()
  cities!: any

  fields = {
    companyName: '',
    fullName: '',
    city: '',
    phone: '',
    profileDescription: '',
    isShowOnSite: true,
  }
  isOpenRecommendedCitiesDropdown = false

  get companyNameErrorText(): string {
    return 'Поле не заполнено'
  }

  public async created(): void {
    this.setFormFields()
  }

  public setFormFields(): void {
    this.fields = { ...this.value }
  }

  public onClickRecommendedCities(cityData: any): void {
    this.fields.city = cityData.name
    this.isOpenRecommendedCitiesDropdown = false
  }

  public onClickOutsideCityField(): void {
    this.isOpenRecommendedCitiesDropdown = false
  }

  public onFocusCityField(): void {
    this.isOpenRecommendedCitiesDropdown = true
  }

  public onBlurCityField(): void {
    this.$v.fields.city.$touch()
    this.isOpenRecommendedCitiesDropdown = false
  }

  // public filterList(list): any[] {
  //   return list.filter((value) =>
  //     value.name
  //       .toLowerCase()
  //       .replace(/ё/g, 'е')
  //       .includes(
  //         this.fields.city
  //           .toLowerCase()
  //           .replace(/ё/g, 'е')
  //           .trim()
  //           .replace(/ +/g, ' ')
  //       )
  //   )
  // }

  public update(): void {
    this.$emit('input', { ...this.fields })
  }
}
